export type Dao = {
  "version": "0.1.0",
  "name": "dao",
  "constants": [
    {
      "name": "FEE_TAG",
      "type": "bytes",
      "value": "[115, 117, 112, 101, 114, 98, 111, 110, 100, 45, 102, 101, 101, 45, 116, 97, 103]"
    },
    {
      "name": "TREASURY_VAULT_TAG",
      "type": "bytes",
      "value": "[115, 117, 112, 101, 114, 98, 111, 110, 100, 45, 116, 114, 101, 97, 115, 117, 114, 121, 45, 116, 97, 103]"
    },
    {
      "name": "GLOBAL_STATE_TAG",
      "type": "bytes",
      "value": "[103, 108, 111, 98, 97, 108, 45, 115, 116, 97, 116, 101]"
    },
    {
      "name": "GOVERNOR_LIST_TAG",
      "type": "bytes",
      "value": "[103, 111, 118, 101, 114, 110, 111, 114, 45, 108, 105, 115, 116]"
    },
    {
      "name": "LNDR_VAULT_TAG",
      "type": "bytes",
      "value": "[108, 110, 100, 114, 45, 118, 97, 117, 108, 116]"
    },
    {
      "name": "USDC_VAULT_TAG",
      "type": "bytes",
      "value": "[117, 115, 100, 99, 45, 118, 97, 117, 108, 116]"
    },
    {
      "name": "USDC_TREASURY_VAULT_TAG",
      "type": "bytes",
      "value": "[117, 115, 100, 99, 45, 116, 114, 101, 97, 115, 117, 114, 121, 45, 118, 97, 117, 108, 116]"
    },
    {
      "name": "VLNDR_VAULT_TAG",
      "type": "bytes",
      "value": "[118, 108, 110, 100, 114, 45, 118, 97, 117, 108, 116]"
    },
    {
      "name": "VLNDR_MINT_TAG",
      "type": "bytes",
      "value": "[118, 108, 110, 100, 114, 45, 109, 105, 110, 116]"
    },
    {
      "name": "LNDR_STAKES_TAG",
      "type": "bytes",
      "value": "[108, 110, 100, 114, 45, 115, 116, 97, 107, 101, 115]"
    },
    {
      "name": "LNDR_STAKE_NONCE_TAG",
      "type": "bytes",
      "value": "[108, 110, 100, 114, 45, 115, 116, 97, 107, 101, 45, 110, 111, 110, 99, 101]"
    },
    {
      "name": "VLNDR_STAKES_TAG",
      "type": "bytes",
      "value": "[118, 108, 110, 100, 114, 45, 115, 116, 97, 107, 101, 115]"
    },
    {
      "name": "EPOCH_STATE_TAG",
      "type": "bytes",
      "value": "[101, 112, 111, 99, 104, 45, 115, 116, 97, 116, 101]"
    },
    {
      "name": "RANKING_TAG",
      "type": "bytes",
      "value": "[114, 97, 110, 107, 105, 110, 103]"
    },
    {
      "name": "WEIGHT_TAG",
      "type": "bytes",
      "value": "[119, 101, 105, 103, 104, 116]"
    },
    {
      "name": "DECIMALS_VLNDR",
      "type": "u8",
      "value": "9"
    },
    {
      "name": "YEAR_TO_MINUTES",
      "type": "u64",
      "value": "525600"
    },
    {
      "name": "EPOCH_PERIOD",
      "type": "u8",
      "value": "0"
    },
    {
      "name": "PRE_GRACE_PERIOD",
      "type": "u8",
      "value": "1"
    },
    {
      "name": "GRACE_PERIOD",
      "type": "u8",
      "value": "2"
    },
    {
      "name": "CHANGE_APPROVED_ADDRESS",
      "type": "u8",
      "value": "3"
    },
    {
      "name": "CHANGE_APPROVED_LIMIT",
      "type": "u8",
      "value": "4"
    },
    {
      "name": "CHANGE_APPROVED_REQUEST_FEE",
      "type": "u8",
      "value": "5"
    },
    {
      "name": "CHANGE_APPROVED_WITHDRAW_FEE",
      "type": "u8",
      "value": "6"
    },
    {
      "name": "CHANGE_UNAPPROVED_REQUEST_FEE",
      "type": "u8",
      "value": "7"
    },
    {
      "name": "CHANGE_UNAPPROVED_WITHDRAW_FEE",
      "type": "u8",
      "value": "8"
    },
    {
      "name": "CHANGE_COMMIT_FEE",
      "type": "u8",
      "value": "9"
    },
    {
      "name": "CHANGE_CLAIM_FEE",
      "type": "u8",
      "value": "10"
    },
    {
      "name": "CHANGE_GAS_FEE",
      "type": "u8",
      "value": "11"
    },
    {
      "name": "CHANGE_EXTRA_PERIOD",
      "type": "u8",
      "value": "12"
    },
    {
      "name": "CHANGE_GOVERNOR_ADDRESS",
      "type": "u8",
      "value": "13"
    },
    {
      "name": "CHANGE_FEE_VAULT_BURNED_RATE",
      "type": "u8",
      "value": "14"
    },
    {
      "name": "CHANGE_USDC_DISTRIBUTION_RATE_FOR_STAKED_VLNDR",
      "type": "u8",
      "value": "15"
    },
    {
      "name": "CHANGE_EXPENSE_ACCOUNT",
      "type": "u8",
      "value": "16"
    },
    {
      "name": "CHANGE_MIN_VLNDR",
      "type": "u8",
      "value": "17"
    },
    {
      "name": "CHANGE_PERMANENT_GOVERNOR",
      "type": "u8",
      "value": "18"
    },
    {
      "name": "MAX_GOVERNORS",
      "type": {
        "defined": "usize"
      },
      "value": "11"
    },
    {
      "name": "PERMANENT_GOVERNORS_NUM",
      "type": {
        "defined": "usize"
      },
      "value": "3"
    },
    {
      "name": "ACTIVE_PROPOSAL",
      "type": "u8",
      "value": "1"
    },
    {
      "name": "SUCCEED_PROPOSAL",
      "type": "u8",
      "value": "2"
    },
    {
      "name": "FAILED_PROPOSAL",
      "type": "u8",
      "value": "3"
    },
    {
      "name": "CANCELLED_PROPOSAL",
      "type": "u8",
      "value": "4"
    },
    {
      "name": "EXPENSE_ACCOUNT_RATE",
      "type": "u8",
      "value": "10"
    },
    {
      "name": "PERMANENT_GOVERNORS_RATE",
      "type": "u8",
      "value": "25"
    },
    {
      "name": "USDC_DISTRIBUTION_RATE",
      "type": "u8",
      "value": "65"
    },
    {
      "name": "MAX_COMMIT_LNDR_DURATION",
      "type": "u64",
      "value": "2628000"
    },
    {
      "name": "DECIMALS_USDC",
      "type": "u8",
      "value": "6"
    }
  ],
  "instructions": [
    {
      "name": "initialize",
      "accounts": [
        {
          "name": "globalState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "governorList",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vlndrMint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "lndrMint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "usdcMint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "lndrVault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vlndrVault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "usdcVault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "usdcTreasuryVault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "epochDuration",
          "type": "u64"
        },
        {
          "name": "graceDuration",
          "type": "u64"
        },
        {
          "name": "preGraceDuration",
          "type": "u64"
        },
        {
          "name": "numPermanentGovernor",
          "type": "u8"
        },
        {
          "name": "numGovernor",
          "type": "u8"
        },
        {
          "name": "minimumProposalCriteria",
          "type": "u8"
        },
        {
          "name": "minimunVlndrStaking",
          "type": "u64"
        },
        {
          "name": "treasuryRevenueDistribution",
          "type": "u32"
        },
        {
          "name": "expenseAccount",
          "type": "publicKey"
        },
        {
          "name": "permarnentGovernor1",
          "type": "publicKey"
        },
        {
          "name": "permarnentGovernor2",
          "type": "publicKey"
        },
        {
          "name": "permarnentGovernor3",
          "type": "publicKey"
        }
      ]
    },
    {
      "name": "updateGlobalState",
      "accounts": [
        {
          "name": "globalState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "governorList",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "epochDuration",
          "type": "u64"
        },
        {
          "name": "graceDuration",
          "type": "u64"
        },
        {
          "name": "preGraceDuration",
          "type": "u64"
        },
        {
          "name": "numPermanentGovernor",
          "type": "u8"
        },
        {
          "name": "numGovernor",
          "type": "u8"
        },
        {
          "name": "minimumProposalCriteria",
          "type": "u8"
        },
        {
          "name": "minimunVlndrStaking",
          "type": "u64"
        },
        {
          "name": "treasuryRevenueDistribution",
          "type": "u32"
        },
        {
          "name": "expenseAccount",
          "type": "publicKey"
        },
        {
          "name": "permarnentGovernor1",
          "type": "publicKey"
        },
        {
          "name": "permarnentGovernor2",
          "type": "publicKey"
        },
        {
          "name": "permarnentGovernor3",
          "type": "publicKey"
        }
      ]
    },
    {
      "name": "commitLndr",
      "accounts": [
        {
          "name": "stake",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "lndrStakeNonce",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "globalState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "userLndrVault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "lndrVault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "userVlndrVault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "lndrMint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vlndrMint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "amount",
          "type": "u64"
        },
        {
          "name": "duration",
          "type": "u64"
        },
        {
          "name": "nonce",
          "type": "u64"
        }
      ]
    },
    {
      "name": "claimLndr",
      "accounts": [
        {
          "name": "stake",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "lndrStakeNonce",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "globalState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "userLndrVault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "lndrVault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "lndrMint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "nonce",
          "type": "u64"
        }
      ]
    },
    {
      "name": "stakeVlndr",
      "accounts": [
        {
          "name": "globalState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vlndrStake",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "epochState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "rank",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "weight",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "governorList",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vlndrVault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "userVlndrVault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vlndrMint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "amount",
          "type": "u64"
        },
        {
          "name": "currentEpoch",
          "type": "u8"
        }
      ]
    },
    {
      "name": "pullReward",
      "accounts": [
        {
          "name": "metalendProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "globalStateMetalend",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "globalStateDao",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "epochState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "daoUsdcVault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "usdcMint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "feeVaultTreasury",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "cpiAuthority",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "epoch",
          "type": "u8"
        }
      ]
    },
    {
      "name": "distributeReward",
      "accounts": [
        {
          "name": "globalStateDao",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "epochState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "usdcMint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "daoUsdcVault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "usdcTreasuryVault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "governorList",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "pg0UsdcVault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "pg1UsdcVault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "pg2UsdcVault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "expenseUsdcVault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "epoch",
          "type": "u8"
        }
      ]
    },
    {
      "name": "updateGovernors",
      "accounts": [
        {
          "name": "globalStateDao",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "epochState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "governorList",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "rank",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "epoch",
          "type": "u8"
        }
      ]
    },
    {
      "name": "claimRewardAndWithdrawVlndr",
      "accounts": [
        {
          "name": "vlndrStake",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "globalState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "epochState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "rank",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "userUsdcVault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "usdcVault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vlndrVault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "userVlndrVault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "nextVlndrStake",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "nextEpochState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "nextRank",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "nextWeight",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "epoch",
          "type": "u8"
        }
      ]
    },
    {
      "name": "claimReward",
      "accounts": [
        {
          "name": "vlndrStake",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "globalState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "usdcVault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "epochState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "userUsdcVault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "epoch",
          "type": "u8"
        }
      ]
    },
    {
      "name": "continueStakeVlndr",
      "accounts": [
        {
          "name": "oldVlndrStake",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vlndrStake",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "globalState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "epochState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "rank",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "weight",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "governorList",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "accountContinue",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "epoch",
          "type": "u8"
        }
      ]
    },
    {
      "name": "willingToBecomeGovernor",
      "accounts": [
        {
          "name": "vlndrStake",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "globalState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "rank",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "epochState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "epoch",
          "type": "u8"
        }
      ]
    },
    {
      "name": "createProposal",
      "accounts": [
        {
          "name": "proposal",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "proposalSeedSrc",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vlndrStake",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "governorList",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "globalState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "actionFlag",
          "type": "u8"
        },
        {
          "name": "id",
          "type": "u8"
        },
        {
          "name": "address",
          "type": "publicKey"
        },
        {
          "name": "value",
          "type": "u64"
        },
        {
          "name": "currentEpoch",
          "type": "u8"
        }
      ]
    },
    {
      "name": "voteProposal",
      "accounts": [
        {
          "name": "proposal",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "proposalSeedSrc",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "governorList",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "globalState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "metalendGlobalState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "metalendProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "cpiAuthority",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "proposalSeedKey",
          "type": "publicKey"
        }
      ],
      "returns": "u8"
    }
  ],
  "accounts": [
    {
      "name": "globalState",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "owner",
            "type": "publicKey"
          },
          {
            "name": "genesisTime",
            "type": "u64"
          },
          {
            "name": "epochDuration",
            "type": "u64"
          },
          {
            "name": "graceDuration",
            "type": "u64"
          },
          {
            "name": "preGraceDuration",
            "type": "u64"
          },
          {
            "name": "numPermanentGovernor",
            "type": "u8"
          },
          {
            "name": "numGovernor",
            "type": "u8"
          },
          {
            "name": "minimumProposalCriteria",
            "type": "u8"
          },
          {
            "name": "minimunVlndrStaking",
            "type": "u64"
          },
          {
            "name": "totalLndrStaked",
            "type": "u64"
          },
          {
            "name": "totalVlndrStaked",
            "type": "u64"
          },
          {
            "name": "lndrMint",
            "type": "publicKey"
          },
          {
            "name": "vlndrMint",
            "type": "publicKey"
          },
          {
            "name": "usdcMint",
            "type": "publicKey"
          },
          {
            "name": "lndrVault",
            "type": "publicKey"
          },
          {
            "name": "vlndrVault",
            "type": "publicKey"
          },
          {
            "name": "usdcVault",
            "type": "publicKey"
          },
          {
            "name": "treasuryRevenueDistribution",
            "type": "u32"
          },
          {
            "name": "expenseAccount",
            "type": "publicKey"
          },
          {
            "name": "usdcTreasuryVault",
            "type": "publicKey"
          },
          {
            "name": "bump",
            "type": "u8"
          }
        ]
      }
    },
    {
      "name": "governorList",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "permanentGovernors",
            "type": {
              "vec": "publicKey"
            }
          },
          {
            "name": "governors",
            "type": {
              "vec": "publicKey"
            }
          }
        ]
      }
    },
    {
      "name": "rank",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "rank",
            "type": {
              "vec": "publicKey"
            }
          },
          {
            "name": "becomeGovernor",
            "type": {
              "vec": "bool"
            }
          }
        ]
      }
    },
    {
      "name": "weight",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "weight",
            "type": {
              "vec": "u64"
            }
          }
        ]
      }
    },
    {
      "name": "epochState",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "epoch",
            "type": "u8"
          },
          {
            "name": "totalWeight",
            "type": "u64"
          },
          {
            "name": "totalStaked",
            "type": "u64"
          },
          {
            "name": "totalReward",
            "type": "u64"
          },
          {
            "name": "totalStakedReward",
            "type": "u64"
          },
          {
            "name": "pullReward",
            "type": "bool"
          },
          {
            "name": "distributedReward",
            "type": "bool"
          },
          {
            "name": "updateGovernors",
            "type": "bool"
          }
        ]
      }
    },
    {
      "name": "proposal",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "proposalSeedSrc",
            "type": "publicKey"
          },
          {
            "name": "creator",
            "type": "publicKey"
          },
          {
            "name": "timeOfCreation",
            "type": "u32"
          },
          {
            "name": "actionFlag",
            "type": "u8"
          },
          {
            "name": "id",
            "type": "u8"
          },
          {
            "name": "address",
            "type": "publicKey"
          },
          {
            "name": "value",
            "type": "u64"
          },
          {
            "name": "epoch",
            "type": "u8"
          },
          {
            "name": "votedList",
            "type": {
              "vec": "publicKey"
            }
          },
          {
            "name": "status",
            "type": "u8"
          },
          {
            "name": "permanentGovNum",
            "type": "u8"
          },
          {
            "name": "normalGovNum",
            "type": "u8"
          }
        ]
      }
    },
    {
      "name": "lndrStake",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "staker",
            "type": "publicKey"
          },
          {
            "name": "amount",
            "type": "u64"
          },
          {
            "name": "releaseTime",
            "type": "u64"
          },
          {
            "name": "claimed",
            "type": "bool"
          }
        ]
      }
    },
    {
      "name": "lndrStakeNonce",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "nonce",
            "type": "u64"
          },
          {
            "name": "totalLndrStaked",
            "type": "u64"
          }
        ]
      }
    },
    {
      "name": "vLndrStake",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "staker",
            "type": "publicKey"
          },
          {
            "name": "epoch",
            "type": "u8"
          },
          {
            "name": "stakeNextEpoch",
            "type": "bool"
          },
          {
            "name": "totalVlndrStaked",
            "type": "u64"
          },
          {
            "name": "estimatedWeight",
            "type": "u64"
          },
          {
            "name": "currentWeight",
            "type": "u64"
          },
          {
            "name": "lastTimeStaked",
            "type": "u64"
          },
          {
            "name": "rewardClaimed",
            "type": "bool"
          }
        ]
      }
    }
  ],
  "errors": [
    {
      "code": 6000,
      "name": "InvalidMoment",
      "msg": "Unstake Lndr: Invalid moment"
    },
    {
      "code": 6001,
      "name": "StakeClaimed",
      "msg": "Unstake Lndr: Stake claimed"
    },
    {
      "code": 6002,
      "name": "InvalidCurrentEpoch",
      "msg": "Invalid current epoch"
    },
    {
      "code": 6003,
      "name": "InvalidCurrentPeriod",
      "msg": "Invalid period"
    },
    {
      "code": 6004,
      "name": "InvalidAmount",
      "msg": "Require amount > 0"
    },
    {
      "code": 6005,
      "name": "AlreadyRunContinueStakeVlndr",
      "msg": "Already run continueStakeVlndr"
    },
    {
      "code": 6006,
      "name": "NoVlndrStaked",
      "msg": "No vlndr staked"
    },
    {
      "code": 6007,
      "name": "InvalidNonce",
      "msg": "Stale Lndr: Invalid nonce"
    },
    {
      "code": 6008,
      "name": "InvalidCreator",
      "msg": "Invalid creator"
    },
    {
      "code": 6009,
      "name": "RequireMoreVlndr",
      "msg": "Require More Vlndr"
    },
    {
      "code": 6010,
      "name": "InvalidProposal",
      "msg": "Invalid proposal"
    },
    {
      "code": 6011,
      "name": "ProposalNotActive",
      "msg": "Proposal is not active"
    },
    {
      "code": 6012,
      "name": "InvalidVoter",
      "msg": "Not governor"
    },
    {
      "code": 6013,
      "name": "GovernorVoted",
      "msg": "Governor voted"
    },
    {
      "code": 6014,
      "name": "RewardClaimed",
      "msg": "Reward claimed"
    },
    {
      "code": 6015,
      "name": "NoReward",
      "msg": "No reward"
    },
    {
      "code": 6016,
      "name": "NoRewardAndVlndr",
      "msg": "No reward and vlndr"
    },
    {
      "code": 6017,
      "name": "InvalidUnstaker",
      "msg": "Governor next epoch cannot unstake"
    },
    {
      "code": 6018,
      "name": "NotInRanking",
      "msg": "Not in ranking"
    },
    {
      "code": 6019,
      "name": "AlreadyBecomeGovernor",
      "msg": "Already become governor"
    },
    {
      "code": 6020,
      "name": "AlreadyPullReward",
      "msg": "Already pull reward"
    },
    {
      "code": 6021,
      "name": "AlreadyDistributedReward",
      "msg": "Already distributed reward"
    },
    {
      "code": 6022,
      "name": "AlreadyUpdateGovernors",
      "msg": "Already update governors"
    },
    {
      "code": 6023,
      "name": "InvalidCommitLndrDuration",
      "msg": "Invalid commit lndr duration"
    },
    {
      "code": 6024,
      "name": "InvalidArithmeticOperation",
      "msg": "Invalid arithmetic operation"
    },
    {
      "code": 6025,
      "name": "InvalidOwner",
      "msg": "Invalid owner"
    }
  ]
};

export const IDL: Dao = {
  "version": "0.1.0",
  "name": "dao",
  "constants": [
    {
      "name": "FEE_TAG",
      "type": "bytes",
      "value": "[115, 117, 112, 101, 114, 98, 111, 110, 100, 45, 102, 101, 101, 45, 116, 97, 103]"
    },
    {
      "name": "TREASURY_VAULT_TAG",
      "type": "bytes",
      "value": "[115, 117, 112, 101, 114, 98, 111, 110, 100, 45, 116, 114, 101, 97, 115, 117, 114, 121, 45, 116, 97, 103]"
    },
    {
      "name": "GLOBAL_STATE_TAG",
      "type": "bytes",
      "value": "[103, 108, 111, 98, 97, 108, 45, 115, 116, 97, 116, 101]"
    },
    {
      "name": "GOVERNOR_LIST_TAG",
      "type": "bytes",
      "value": "[103, 111, 118, 101, 114, 110, 111, 114, 45, 108, 105, 115, 116]"
    },
    {
      "name": "LNDR_VAULT_TAG",
      "type": "bytes",
      "value": "[108, 110, 100, 114, 45, 118, 97, 117, 108, 116]"
    },
    {
      "name": "USDC_VAULT_TAG",
      "type": "bytes",
      "value": "[117, 115, 100, 99, 45, 118, 97, 117, 108, 116]"
    },
    {
      "name": "USDC_TREASURY_VAULT_TAG",
      "type": "bytes",
      "value": "[117, 115, 100, 99, 45, 116, 114, 101, 97, 115, 117, 114, 121, 45, 118, 97, 117, 108, 116]"
    },
    {
      "name": "VLNDR_VAULT_TAG",
      "type": "bytes",
      "value": "[118, 108, 110, 100, 114, 45, 118, 97, 117, 108, 116]"
    },
    {
      "name": "VLNDR_MINT_TAG",
      "type": "bytes",
      "value": "[118, 108, 110, 100, 114, 45, 109, 105, 110, 116]"
    },
    {
      "name": "LNDR_STAKES_TAG",
      "type": "bytes",
      "value": "[108, 110, 100, 114, 45, 115, 116, 97, 107, 101, 115]"
    },
    {
      "name": "LNDR_STAKE_NONCE_TAG",
      "type": "bytes",
      "value": "[108, 110, 100, 114, 45, 115, 116, 97, 107, 101, 45, 110, 111, 110, 99, 101]"
    },
    {
      "name": "VLNDR_STAKES_TAG",
      "type": "bytes",
      "value": "[118, 108, 110, 100, 114, 45, 115, 116, 97, 107, 101, 115]"
    },
    {
      "name": "EPOCH_STATE_TAG",
      "type": "bytes",
      "value": "[101, 112, 111, 99, 104, 45, 115, 116, 97, 116, 101]"
    },
    {
      "name": "RANKING_TAG",
      "type": "bytes",
      "value": "[114, 97, 110, 107, 105, 110, 103]"
    },
    {
      "name": "WEIGHT_TAG",
      "type": "bytes",
      "value": "[119, 101, 105, 103, 104, 116]"
    },
    {
      "name": "DECIMALS_VLNDR",
      "type": "u8",
      "value": "9"
    },
    {
      "name": "YEAR_TO_MINUTES",
      "type": "u64",
      "value": "525600"
    },
    {
      "name": "EPOCH_PERIOD",
      "type": "u8",
      "value": "0"
    },
    {
      "name": "PRE_GRACE_PERIOD",
      "type": "u8",
      "value": "1"
    },
    {
      "name": "GRACE_PERIOD",
      "type": "u8",
      "value": "2"
    },
    {
      "name": "CHANGE_APPROVED_ADDRESS",
      "type": "u8",
      "value": "3"
    },
    {
      "name": "CHANGE_APPROVED_LIMIT",
      "type": "u8",
      "value": "4"
    },
    {
      "name": "CHANGE_APPROVED_REQUEST_FEE",
      "type": "u8",
      "value": "5"
    },
    {
      "name": "CHANGE_APPROVED_WITHDRAW_FEE",
      "type": "u8",
      "value": "6"
    },
    {
      "name": "CHANGE_UNAPPROVED_REQUEST_FEE",
      "type": "u8",
      "value": "7"
    },
    {
      "name": "CHANGE_UNAPPROVED_WITHDRAW_FEE",
      "type": "u8",
      "value": "8"
    },
    {
      "name": "CHANGE_COMMIT_FEE",
      "type": "u8",
      "value": "9"
    },
    {
      "name": "CHANGE_CLAIM_FEE",
      "type": "u8",
      "value": "10"
    },
    {
      "name": "CHANGE_GAS_FEE",
      "type": "u8",
      "value": "11"
    },
    {
      "name": "CHANGE_EXTRA_PERIOD",
      "type": "u8",
      "value": "12"
    },
    {
      "name": "CHANGE_GOVERNOR_ADDRESS",
      "type": "u8",
      "value": "13"
    },
    {
      "name": "CHANGE_FEE_VAULT_BURNED_RATE",
      "type": "u8",
      "value": "14"
    },
    {
      "name": "CHANGE_USDC_DISTRIBUTION_RATE_FOR_STAKED_VLNDR",
      "type": "u8",
      "value": "15"
    },
    {
      "name": "CHANGE_EXPENSE_ACCOUNT",
      "type": "u8",
      "value": "16"
    },
    {
      "name": "CHANGE_MIN_VLNDR",
      "type": "u8",
      "value": "17"
    },
    {
      "name": "CHANGE_PERMANENT_GOVERNOR",
      "type": "u8",
      "value": "18"
    },
    {
      "name": "MAX_GOVERNORS",
      "type": {
        "defined": "usize"
      },
      "value": "11"
    },
    {
      "name": "PERMANENT_GOVERNORS_NUM",
      "type": {
        "defined": "usize"
      },
      "value": "3"
    },
    {
      "name": "ACTIVE_PROPOSAL",
      "type": "u8",
      "value": "1"
    },
    {
      "name": "SUCCEED_PROPOSAL",
      "type": "u8",
      "value": "2"
    },
    {
      "name": "FAILED_PROPOSAL",
      "type": "u8",
      "value": "3"
    },
    {
      "name": "CANCELLED_PROPOSAL",
      "type": "u8",
      "value": "4"
    },
    {
      "name": "EXPENSE_ACCOUNT_RATE",
      "type": "u8",
      "value": "10"
    },
    {
      "name": "PERMANENT_GOVERNORS_RATE",
      "type": "u8",
      "value": "25"
    },
    {
      "name": "USDC_DISTRIBUTION_RATE",
      "type": "u8",
      "value": "65"
    },
    {
      "name": "MAX_COMMIT_LNDR_DURATION",
      "type": "u64",
      "value": "2628000"
    },
    {
      "name": "DECIMALS_USDC",
      "type": "u8",
      "value": "6"
    }
  ],
  "instructions": [
    {
      "name": "initialize",
      "accounts": [
        {
          "name": "globalState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "governorList",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vlndrMint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "lndrMint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "usdcMint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "lndrVault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vlndrVault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "usdcVault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "usdcTreasuryVault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "epochDuration",
          "type": "u64"
        },
        {
          "name": "graceDuration",
          "type": "u64"
        },
        {
          "name": "preGraceDuration",
          "type": "u64"
        },
        {
          "name": "numPermanentGovernor",
          "type": "u8"
        },
        {
          "name": "numGovernor",
          "type": "u8"
        },
        {
          "name": "minimumProposalCriteria",
          "type": "u8"
        },
        {
          "name": "minimunVlndrStaking",
          "type": "u64"
        },
        {
          "name": "treasuryRevenueDistribution",
          "type": "u32"
        },
        {
          "name": "expenseAccount",
          "type": "publicKey"
        },
        {
          "name": "permarnentGovernor1",
          "type": "publicKey"
        },
        {
          "name": "permarnentGovernor2",
          "type": "publicKey"
        },
        {
          "name": "permarnentGovernor3",
          "type": "publicKey"
        }
      ]
    },
    {
      "name": "updateGlobalState",
      "accounts": [
        {
          "name": "globalState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "governorList",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "epochDuration",
          "type": "u64"
        },
        {
          "name": "graceDuration",
          "type": "u64"
        },
        {
          "name": "preGraceDuration",
          "type": "u64"
        },
        {
          "name": "numPermanentGovernor",
          "type": "u8"
        },
        {
          "name": "numGovernor",
          "type": "u8"
        },
        {
          "name": "minimumProposalCriteria",
          "type": "u8"
        },
        {
          "name": "minimunVlndrStaking",
          "type": "u64"
        },
        {
          "name": "treasuryRevenueDistribution",
          "type": "u32"
        },
        {
          "name": "expenseAccount",
          "type": "publicKey"
        },
        {
          "name": "permarnentGovernor1",
          "type": "publicKey"
        },
        {
          "name": "permarnentGovernor2",
          "type": "publicKey"
        },
        {
          "name": "permarnentGovernor3",
          "type": "publicKey"
        }
      ]
    },
    {
      "name": "commitLndr",
      "accounts": [
        {
          "name": "stake",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "lndrStakeNonce",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "globalState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "userLndrVault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "lndrVault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "userVlndrVault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "lndrMint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vlndrMint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "amount",
          "type": "u64"
        },
        {
          "name": "duration",
          "type": "u64"
        },
        {
          "name": "nonce",
          "type": "u64"
        }
      ]
    },
    {
      "name": "claimLndr",
      "accounts": [
        {
          "name": "stake",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "lndrStakeNonce",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "globalState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "userLndrVault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "lndrVault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "lndrMint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "nonce",
          "type": "u64"
        }
      ]
    },
    {
      "name": "stakeVlndr",
      "accounts": [
        {
          "name": "globalState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vlndrStake",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "epochState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "rank",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "weight",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "governorList",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vlndrVault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "userVlndrVault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vlndrMint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "amount",
          "type": "u64"
        },
        {
          "name": "currentEpoch",
          "type": "u8"
        }
      ]
    },
    {
      "name": "pullReward",
      "accounts": [
        {
          "name": "metalendProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "globalStateMetalend",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "globalStateDao",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "epochState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "daoUsdcVault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "usdcMint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "feeVaultTreasury",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "cpiAuthority",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "epoch",
          "type": "u8"
        }
      ]
    },
    {
      "name": "distributeReward",
      "accounts": [
        {
          "name": "globalStateDao",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "epochState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "usdcMint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "daoUsdcVault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "usdcTreasuryVault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "governorList",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "pg0UsdcVault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "pg1UsdcVault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "pg2UsdcVault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "expenseUsdcVault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "epoch",
          "type": "u8"
        }
      ]
    },
    {
      "name": "updateGovernors",
      "accounts": [
        {
          "name": "globalStateDao",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "epochState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "governorList",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "rank",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "epoch",
          "type": "u8"
        }
      ]
    },
    {
      "name": "claimRewardAndWithdrawVlndr",
      "accounts": [
        {
          "name": "vlndrStake",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "globalState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "epochState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "rank",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "userUsdcVault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "usdcVault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vlndrVault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "userVlndrVault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "nextVlndrStake",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "nextEpochState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "nextRank",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "nextWeight",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "epoch",
          "type": "u8"
        }
      ]
    },
    {
      "name": "claimReward",
      "accounts": [
        {
          "name": "vlndrStake",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "globalState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "usdcVault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "epochState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "userUsdcVault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "epoch",
          "type": "u8"
        }
      ]
    },
    {
      "name": "continueStakeVlndr",
      "accounts": [
        {
          "name": "oldVlndrStake",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vlndrStake",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "globalState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "epochState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "rank",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "weight",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "governorList",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "accountContinue",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "epoch",
          "type": "u8"
        }
      ]
    },
    {
      "name": "willingToBecomeGovernor",
      "accounts": [
        {
          "name": "vlndrStake",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "globalState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "rank",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "epochState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "epoch",
          "type": "u8"
        }
      ]
    },
    {
      "name": "createProposal",
      "accounts": [
        {
          "name": "proposal",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "proposalSeedSrc",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vlndrStake",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "governorList",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "globalState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "actionFlag",
          "type": "u8"
        },
        {
          "name": "id",
          "type": "u8"
        },
        {
          "name": "address",
          "type": "publicKey"
        },
        {
          "name": "value",
          "type": "u64"
        },
        {
          "name": "currentEpoch",
          "type": "u8"
        }
      ]
    },
    {
      "name": "voteProposal",
      "accounts": [
        {
          "name": "proposal",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "proposalSeedSrc",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "governorList",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "globalState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "metalendGlobalState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "metalendProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "cpiAuthority",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "proposalSeedKey",
          "type": "publicKey"
        }
      ],
      "returns": "u8"
    }
  ],
  "accounts": [
    {
      "name": "globalState",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "owner",
            "type": "publicKey"
          },
          {
            "name": "genesisTime",
            "type": "u64"
          },
          {
            "name": "epochDuration",
            "type": "u64"
          },
          {
            "name": "graceDuration",
            "type": "u64"
          },
          {
            "name": "preGraceDuration",
            "type": "u64"
          },
          {
            "name": "numPermanentGovernor",
            "type": "u8"
          },
          {
            "name": "numGovernor",
            "type": "u8"
          },
          {
            "name": "minimumProposalCriteria",
            "type": "u8"
          },
          {
            "name": "minimunVlndrStaking",
            "type": "u64"
          },
          {
            "name": "totalLndrStaked",
            "type": "u64"
          },
          {
            "name": "totalVlndrStaked",
            "type": "u64"
          },
          {
            "name": "lndrMint",
            "type": "publicKey"
          },
          {
            "name": "vlndrMint",
            "type": "publicKey"
          },
          {
            "name": "usdcMint",
            "type": "publicKey"
          },
          {
            "name": "lndrVault",
            "type": "publicKey"
          },
          {
            "name": "vlndrVault",
            "type": "publicKey"
          },
          {
            "name": "usdcVault",
            "type": "publicKey"
          },
          {
            "name": "treasuryRevenueDistribution",
            "type": "u32"
          },
          {
            "name": "expenseAccount",
            "type": "publicKey"
          },
          {
            "name": "usdcTreasuryVault",
            "type": "publicKey"
          },
          {
            "name": "bump",
            "type": "u8"
          }
        ]
      }
    },
    {
      "name": "governorList",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "permanentGovernors",
            "type": {
              "vec": "publicKey"
            }
          },
          {
            "name": "governors",
            "type": {
              "vec": "publicKey"
            }
          }
        ]
      }
    },
    {
      "name": "rank",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "rank",
            "type": {
              "vec": "publicKey"
            }
          },
          {
            "name": "becomeGovernor",
            "type": {
              "vec": "bool"
            }
          }
        ]
      }
    },
    {
      "name": "weight",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "weight",
            "type": {
              "vec": "u64"
            }
          }
        ]
      }
    },
    {
      "name": "epochState",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "epoch",
            "type": "u8"
          },
          {
            "name": "totalWeight",
            "type": "u64"
          },
          {
            "name": "totalStaked",
            "type": "u64"
          },
          {
            "name": "totalReward",
            "type": "u64"
          },
          {
            "name": "totalStakedReward",
            "type": "u64"
          },
          {
            "name": "pullReward",
            "type": "bool"
          },
          {
            "name": "distributedReward",
            "type": "bool"
          },
          {
            "name": "updateGovernors",
            "type": "bool"
          }
        ]
      }
    },
    {
      "name": "proposal",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "proposalSeedSrc",
            "type": "publicKey"
          },
          {
            "name": "creator",
            "type": "publicKey"
          },
          {
            "name": "timeOfCreation",
            "type": "u32"
          },
          {
            "name": "actionFlag",
            "type": "u8"
          },
          {
            "name": "id",
            "type": "u8"
          },
          {
            "name": "address",
            "type": "publicKey"
          },
          {
            "name": "value",
            "type": "u64"
          },
          {
            "name": "epoch",
            "type": "u8"
          },
          {
            "name": "votedList",
            "type": {
              "vec": "publicKey"
            }
          },
          {
            "name": "status",
            "type": "u8"
          },
          {
            "name": "permanentGovNum",
            "type": "u8"
          },
          {
            "name": "normalGovNum",
            "type": "u8"
          }
        ]
      }
    },
    {
      "name": "lndrStake",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "staker",
            "type": "publicKey"
          },
          {
            "name": "amount",
            "type": "u64"
          },
          {
            "name": "releaseTime",
            "type": "u64"
          },
          {
            "name": "claimed",
            "type": "bool"
          }
        ]
      }
    },
    {
      "name": "lndrStakeNonce",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "nonce",
            "type": "u64"
          },
          {
            "name": "totalLndrStaked",
            "type": "u64"
          }
        ]
      }
    },
    {
      "name": "vLndrStake",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "staker",
            "type": "publicKey"
          },
          {
            "name": "epoch",
            "type": "u8"
          },
          {
            "name": "stakeNextEpoch",
            "type": "bool"
          },
          {
            "name": "totalVlndrStaked",
            "type": "u64"
          },
          {
            "name": "estimatedWeight",
            "type": "u64"
          },
          {
            "name": "currentWeight",
            "type": "u64"
          },
          {
            "name": "lastTimeStaked",
            "type": "u64"
          },
          {
            "name": "rewardClaimed",
            "type": "bool"
          }
        ]
      }
    }
  ],
  "errors": [
    {
      "code": 6000,
      "name": "InvalidMoment",
      "msg": "Unstake Lndr: Invalid moment"
    },
    {
      "code": 6001,
      "name": "StakeClaimed",
      "msg": "Unstake Lndr: Stake claimed"
    },
    {
      "code": 6002,
      "name": "InvalidCurrentEpoch",
      "msg": "Invalid current epoch"
    },
    {
      "code": 6003,
      "name": "InvalidCurrentPeriod",
      "msg": "Invalid period"
    },
    {
      "code": 6004,
      "name": "InvalidAmount",
      "msg": "Require amount > 0"
    },
    {
      "code": 6005,
      "name": "AlreadyRunContinueStakeVlndr",
      "msg": "Already run continueStakeVlndr"
    },
    {
      "code": 6006,
      "name": "NoVlndrStaked",
      "msg": "No vlndr staked"
    },
    {
      "code": 6007,
      "name": "InvalidNonce",
      "msg": "Stale Lndr: Invalid nonce"
    },
    {
      "code": 6008,
      "name": "InvalidCreator",
      "msg": "Invalid creator"
    },
    {
      "code": 6009,
      "name": "RequireMoreVlndr",
      "msg": "Require More Vlndr"
    },
    {
      "code": 6010,
      "name": "InvalidProposal",
      "msg": "Invalid proposal"
    },
    {
      "code": 6011,
      "name": "ProposalNotActive",
      "msg": "Proposal is not active"
    },
    {
      "code": 6012,
      "name": "InvalidVoter",
      "msg": "Not governor"
    },
    {
      "code": 6013,
      "name": "GovernorVoted",
      "msg": "Governor voted"
    },
    {
      "code": 6014,
      "name": "RewardClaimed",
      "msg": "Reward claimed"
    },
    {
      "code": 6015,
      "name": "NoReward",
      "msg": "No reward"
    },
    {
      "code": 6016,
      "name": "NoRewardAndVlndr",
      "msg": "No reward and vlndr"
    },
    {
      "code": 6017,
      "name": "InvalidUnstaker",
      "msg": "Governor next epoch cannot unstake"
    },
    {
      "code": 6018,
      "name": "NotInRanking",
      "msg": "Not in ranking"
    },
    {
      "code": 6019,
      "name": "AlreadyBecomeGovernor",
      "msg": "Already become governor"
    },
    {
      "code": 6020,
      "name": "AlreadyPullReward",
      "msg": "Already pull reward"
    },
    {
      "code": 6021,
      "name": "AlreadyDistributedReward",
      "msg": "Already distributed reward"
    },
    {
      "code": 6022,
      "name": "AlreadyUpdateGovernors",
      "msg": "Already update governors"
    },
    {
      "code": 6023,
      "name": "InvalidCommitLndrDuration",
      "msg": "Invalid commit lndr duration"
    },
    {
      "code": 6024,
      "name": "InvalidArithmeticOperation",
      "msg": "Invalid arithmetic operation"
    },
    {
      "code": 6025,
      "name": "InvalidOwner",
      "msg": "Invalid owner"
    }
  ]
};
