import { PublicKey } from "@solana/web3.js";
import { NETWORK_RPC } from "./constants";

import idl from "./idl/metalend.json";
import dao_idl from "./idl/dao.json";
import aution_idl from "./idl/auction.json";

export const WSOL_MINT_KEY = new PublicKey(
  "So11111111111111111111111111111111111111112"
);

export const LEND_IDL = idl;
export const DAO_IDL = dao_idl;
export const AUCTION_IDL = aution_idl;

export const TOKEN_INFO = {
  mainnet: {},
  devnet: [
    {
      token_name: "SPL-USDC",
      token_symbol: "USDC",
      token_mint: "Raisg93hadrPtL4YG49JyZeT4KACJg1T7gB7sPpdNUe",
    },
  ],
  localnet: [
    {
      token_name: "SPL-USDC",
      token_symbol: "USDC",
      token_mint: "Raisg93hadrPtL4YG49JyZeT4KACJg1T7gB7sPpdNUe",
    },
  ],
};

export const DAO_PROGRAM_ID = new PublicKey(
  NETWORK_RPC === "localnet"
    ? "7oQMtAiht1jLmyVcs8yDLzWifpn7rMHMFdxWojtmnYJp"
    : "7oQMtAiht1jLmyVcs8yDLzWifpn7rMHMFdxWojtmnYJp"
);

export const AUCTION_PROGRAM_ID = new PublicKey(
  NETWORK_RPC === "localnet"
    ? "6JKiz5ZExVXFDUViwcEEtGZn3fDv66YnEVD2J25HM2Bx"
    : "6JKiz5ZExVXFDUViwcEEtGZn3fDv66YnEVD2J25HM2Bx"
);

export const LEND_PROGRAM_ID = new PublicKey(
  NETWORK_RPC === "localnet"
    ? "5y6iVoBWAvQj8xQkq6EH1yeRnnuY4GGHF87aWeqkSm8X"
    : "5y6iVoBWAvQj8xQkq6EH1yeRnnuY4GGHF87aWeqkSm8X"
);

console.log(NETWORK_RPC);
export const SB_MINT_ADDRESS =
  NETWORK_RPC === "devnet"
    ? "RaiRQjbzkEskpx69iX3RDv41zSppJfEMNU8K6fDnc66"
    : "RaiRQjbzkEskpx69iX3RDv41zSppJfEMNU8K6fDnc66";
export const USDC_MINT_ADDRESS =
  NETWORK_RPC === "devnet"
    ? "Raisg93hadrPtL4YG49JyZeT4KACJg1T7gB7sPpdNUe"
    : "Raisg93hadrPtL4YG49JyZeT4KACJg1T7gB7sPpdNUe";

export let TOKEN_LIST: any =
  NETWORK_RPC === "localnet" ? TOKEN_INFO.localnet : TOKEN_INFO.devnet;

if (NETWORK_RPC === "mainnet-beta") TOKEN_LIST = TOKEN_INFO.mainnet;

export const EMPTY_ADDRESS = "11111111111111111111111111111111";

export const GOVERNOR_ADDRESS = '7XHEUVt5oEKwJHwxGBuUbU4Z4UFuyyLkUqUDgM9VRv2P';
export const APPROVER0_ADDRESS = 'CHFW6T86Eg3FMiJuVq6mQsKPoKiNTjBeNrhfm8YdRzjE';

export const VLNDR = 'DkeNz7qJRYNRsK4PtgAs8Prpq4naaRZ3xA3rAyVDn7Qz'; 
export const LNDR_VAULT_PUBLICKEY = 'CHBgjv7Utqwg1PPcKf1i5wVpMAMmN5XMaxk8CtT9T3qU'; 
export const VLNDR_VAULT_PUBLICKEY = 'Du6FvSrcVhkmEmFfeLGnQKuBKcTFkEv57G4PobQJm6Zv'; 
export const USDC_VAULT_PUBLICKEY = '9gP4yVjwib88GdnFPkEJcKv853d8YD7cevA8rBNHUQtK';